import { queryOptions } from "@tanstack/vue-query";
import camelize from "camelcase-keys";
import { FetchError } from "ofetch";
import { useDonorAccessToken } from "./composables";

export const authQueries = {
  all: () => ["auth"],
  self: () =>
    queryOptions({
      queryKey: [...authQueries.all(), "self"],
      queryFn: async () => {
        const token = useDonorAccessToken();

        if (!token.value) {
          return null;
        }

        const client = useApiClient();

        try {
          const response = await client<SelfResponse>(
            "/donor/auth/getSelfData"
          );

          return camelize(response.donor, { deep: true });
        } catch (e) {
          if (e instanceof FetchError) {
            if (e.response?.status === 401 || e.response?.status === 403) {
              token.value = null;
              return null;
            }
          }

          throw e;
        }
      },
      staleTime: 1000 * 60 * 60,
    }),
  preferences: () =>
    queryOptions({
      queryKey: ["preferences"],
      queryFn: async () => {
        const token = useDonorAccessToken();

        if (!token.value) {
          return [];
        }

        const client = useApiClient();

        const response = await client<Preference[]>("/donor/auth/preferences");

        return response;
      },
    }),
};

export type Preference = {
  key: string;
  value: string;
};

type SelfResponse = {
  donor: {
    id: number;
    name: string;
    email: string;
    avatar_url: string | null;
    members: Array<{
      id: number;
      name: string;
      email: string;
      deleted_at: string | null;
      is_anonymous: boolean;
      referral_link_id: number | null;
      referral_code: string;
      total_amount_donated: number;
      points: number;
      badges: Array<{
        id: number;
        achievedAt: string;
        organizationId: number;
        title: string;
        description: string;
        icon: string;
        criteria:
          | "REFERRAL_NUMBER"
          | "ORDER_OF_SIGNUP"
          | "MONTHS_DONATED"
          | "LEADERBOARD_POSITION";
        value: number;
        perks: Array<{
          id: number;
          badge_id: number;
          name: string;
          description: string | null;
          deliver_url: string | null;
          created_at: string;
          updated_at: string;
        }>;
        createdAt: string;
        updatedAt: string;
      }>;
      organization: {
        id: number;
        name: string;
        brand_primary_color: string;
        by: string;
        is_approved_charity: boolean;
        default_url: {
          id: number;
          url: string;
          external: 0 | 1;
          active: 0 | 1;
          organizationId: number;
        };
        tags: Array<{
          id: number;
          name: string;
        }>;
      };
    }>;
  };
};
