export const NEW_CIRCLES = [
  "selenagomez",
  "alok",
  "leehutchinson",
  "moseswest",
  "peterbond",
  "shabani",
  "juixxe",
] as const;

export type NewCircleSlug = (typeof NEW_CIRCLES)[number];

export function useIsNewCircle(slug: MaybeRefOrGetter<string>) {
  return computed(() => NEW_CIRCLES.includes(toValue(slug) as NewCircleSlug));
}
